import React from "react"
import Layout from "../../../components/layout"
import EfemeridesM from "../../../components/efemeridesm"
import CommentsFB from "../../../components/Comments"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const EfeEnero = () => (
  <Layout>
    <GatsbySeo
      title="Efemérides - Agosto"
      titleTemplate="%s | Eduardo Ceballos"
      description="Efemérides de Salta por Eduardo Ceballos"
      canonical="https://eduardoceballos.com/libros/efemerides/agosto"
      openGraph={{
        url: "https://eduardoceballos.com/libros/efemerides/agosto",
        title: "Efemérides - Agosto | Eduardo Ceballos",
        description: "Efemérides de Salta por Eduardo Ceballos",
        images: [
          {
            url: `https://res.cloudinary.com/lagexpress/image/upload/v1597476577/strapi/tapalibroconozcasaltaatravesdesusefemerides_4670275357.jpg`,
            alt:
              "Tapa del libro del libro Conozca Salta a través de sus Efemérides",
          },
        ],
        site_name: "Eduardo Ceballos",
      }}
      twitter={{
        handle: "@PoetadeSalta",
        site: "@PoetadeSalta",
        cardType: "summary_large_image",
      }}
    />
    <h1>Conozca Salta a través de sus Efemérides – AGOSTO</h1>
    <hr />
    <EfemeridesM />
    <hr />
    <p>
      1 de agosto de 1909: Murió en Salta, Higinio Falcón. Nació en Salta en
      1844. En 1867 fue prisionero de Felipe Varela, logrando escapara. En 1871
      se trasladó a Rivadavia a orillas del Río Bermejo. Se incorporó en 1881 a
      la expedición del coronel Juan Nepomuceno Solá, que recorrió por primera
      vez el Chaco Central (hoy provincia de Formosa).
    </p>
    <p>
      1 de agosto de 1931:&nbsp;Nació en Galicia, España, Antonio Vilariño.
      Desde joven se radicó en Argentina. Primero en la ciudad de Buenos Aires,
      luego Orán, en la provincia de Salta y por último en la ciudad de Salta.
      Publicó los siguientes libros: “Burbujas y eslabones” (1956); “La vida en
      traje de calle” (1959); “Los adanes negros” (1961); “Poemas” (1966);
      “Baladas para el Adán Astral” (aparecido en Salta el mismo día que llegaba
      el primer hombre a la luna, el 20 de julio de 1969); “Algos” (1977);
      “Algos II” (1983); “Almoralgos” (1987 – editado por el Instituto Cultural
      Andino y prologado por el poeta Eduardo Ceballos); Fue autor también de
      muchas obras de teatro. Murió en Salta el 4 de febrero de 1990.
    </p>
    <p>
      1 de agosto de 1934: Fue aceptada la renuncia del Obispo de Salta,
      Monseñor Julio Campero y Aráoz y desde este día gobernaba la Diocesis el
      Canónigo Honorarios, Monseñor Manuel Francisco Elizondo, quien había sido
      nombrado Vicario Capitular por el Cabildo Eclesiástico de Salta.
    </p>
    <p>
      1 de agosto de 1953: Nació Andrés Julio Desimone. Abogado y empresario. Es
      el conductor de la gran empresa dedicada al turismo Dinar S.A.
    </p>
    <p>
      1 de agosto de 1966: Asumió como interventor Federal de la provincia de
      Salta, el general de brigada (RE) Héctor D´ Andrea.
    </p>
    <p>
      1 de agosto de 1972: Murió Monseñor Miguel Angel Vergara. Fue vicario
      general del Arzobispado, deán de la Catedral, académico de laAcademia
      Nacional de la Historia y director del MUSEO/ Regional del Norte.
    </p>
    <p>
      1 de agosto de 1979: Se inauguró la sucursal Metán, del Banco de Préstamos
      y Asistencia Social.
    </p>
    <p>
      2 de agosto de 1915: Nació en Salta, Néstor Saavedra. Publicó en verso y
      en prosa. Gran productor de obras. Incursionó en la novela. Fue premiado
      en la provincia y logró un segundo premio regional con sus libros “El
      Camino de la Sangre”. Algunos de los títulos publicados: “Locura en la
      montañas”, “El señor gobernador y la Insurrección”, “Ciudad
      Septentrional”, “El camino de la sangre”, “El silencio de los
      guerrilleros”, “Los viejos ayeres”, “El camino de la sangre”, “Variedad”,
      “Cada atardecer, yo muero”, “Tartagal Village”, “El Asalto”, “La dolorosa
      aureola de Mamá” (primer premio de cuento), “Los aventureros del Hotel
      Salta” y otros.
    </p>
    <p>
      2 de agosto de 1924: Nació Juana Dib. Ejerció la docencia en escuelas
      rurales y en la ciudad de Salta. Fue profesora de Castellano, miembro de
      la Junta de Clasificación y Disciplina del Concejo General de Educación,
      Fundadora y Directora de la Revista Escolar Chispitas. Autora de Obras de
      Literatura Escolar, aprobadas por la provincia. Libretista de radio. Dió
      charlas y recitales. Fue vocal de la Caja de Previsión Social, Miembro
      Consejero del Instituto Argentino-Arabe de Cultura, filial Salta. Miembro
      de la Sociedad Argentina de Escritores y Miembro del Centro Salteño de
      investigaciones de la cultura Arabe. Fue incluída en la antología “Cien
      Poetisas del NOA”, II Parte. Publicó los siguientes libros de poemas: “El
      Milagro de la rosa”; “Las Doradas”, “Las dos Vertientes”; “La Mandrágora”.
      Tiene obras inéditas. Colaboró en diarios y revistas. Actuó como jurado en
      distintos concursos literarios.
    </p>
    <p>
      2 de agosto de 1936: Nació en Salta, el poeta José Gallardo. Publicó en
      versos “Las tardes pensativas”. Incursionó con éxito en la canción
      popular. Fue conductor de programas radiales folklóricos y animador de
      festivales.
    </p>
    <p>
      2 de agosto de 1993: Falleció el destacado locutor radial Enrique
      Fernández, quien se desempeñó por muchos años en LV9 Radio Salta.
    </p>
    <p>
      3 de agosto de 1767: Fueron detenidos en Salta los jesuitas. Por orden de
      la Corona de España, el gobernador del Tucumán don Juan Manuel Fernández
      Campero, dió cumplimiento en Salta a lo dispuesto por el Rey. A los pocos
      días se los trasladó a Buenos Aires.
    </p>
    <p>
      3 de agosto de 1814: Martín Miguel de Güemes, al frente de sus gauchos
      derrotó a los realistas al mando del general Joaquín de la Pezuela y
      recuperó la ciudad de Salta.
    </p>
    <p>
      3 de agosto de 1943: Nació Edmundo del Cerro – Médico psiquiatra. Poeta y
      escritor. Logró el tercer premio regional de literatura con su obra El
      Paraíso. Publicó también La Miel del Higo, Espacio Agónico, Los árboles
      del incienso, Bebidas alcohólicas en el canto popular de Salta, Ajuste de
      Cuenta. En la actualidad es vicepresidente del Círculo Médico de Salta.
    </p>
    <p>
      4 de agosto de 1795: Nació en Salta, Domingo de la Riestra. Se doctoró en
      derecho canónico. Recibió la consagración sacerdotal del 1º Obispo de
      Salta, Monseñor Videla del Pino (1819). Se desempeñó como cura párroco en
      Chicoana. Fue Secretario Capitular del Obispado. Murió en 1840.
    </p>
    <p>
      4 de agosto de 1887: Murió en Salta, en plena actividad eclesiástica, el
      sacerdote Isidoro Fernández. Fue alumno de Mariano Cabezón. Estudió en la
      Universidad de Chuquisaca, donde fue ordenado sacerdote, el 24 de
      diciembre de 1839. Cantó su primera misa en Salta, en el altar de la
      Virgen del Carmen, en la vieja catedral de Salta, el 22 de enero de 1840.
      Fue vicario provisor y gobernador eclesiástico. Sus restos mortales
      descansan en el convento San Bernardo, fundado por este sacerdote.
    </p>
    <p>
      4 de agosto de 1897: Nació el doctor Elio Alderete. Se doctoró en
      Medicina. Estuvo en el servico de Cirugía del doctor Finochetto. Fue
      fundador del Sanatorio del Carmen. Diputado y senador provincial,
      intendente de la ciudad de Salta. Candidato a gobernador.
    </p>
    <p>
      4 de agosto de 1909: Murió en Salta, Efraín Corbalán, nacido en Cachi en
      1843. Diputado provincial, hacendado prominente de la provinia.
    </p>
    <p>
      4 de agosto de 1952: Nació en Corrientes, Gustavo Ramón Fantozzi.
      Periodista que se desempaña como Jefe de Prensa de Canal 11. Se
      especializa en TURISMO, sociología. Realizó documentales de turismo de
      aventura. Es el conductor de los informativos de Canal 11.
    </p>
    <p>
      4 de agosto de 1975: Se inauguró en la ciudad de San Ramón de la Nueva
      Orán, provincia de Salta, la sucursal del Banco de Préstamos y Asistencia
      Social. En la actualidad, esta sucursal está a cargo del señor Martín
      Guerrero, hombre reconocido por toda la comunidad, que valora el gran
      aporte que realiza por esta ciudad.
    </p>
    <p>
      5 de agosto de 1878:&nbsp;Nació en Salta, Robustiano Patrón Costas, doctor
      en jurisprudencia (1901). A los 23 años fue ministro de hacienda.
      Gobernador de Salta desde el 20 de enero de 1913 hasta el 20 de febrero de
      1916. Ocupó en forma interina el sillón presidencial de la República. En
      1918 fundó el ingenio San Martín del Tabacal. Colaboró en la fundación de
      la Universidad Católica de Salta. El Papa Paulo VI le confirió el título
      de Caballero Pontífico de la orden de San Gregorio Magno y la Academia
      Nacional de Ciencias Económicas los nombró Miembro de Número. Falleció el
      24 de septiembre de 1965.
    </p>
    <p>
      5 de agosto de 1970: Se creó la Ley Nº 4365 el Municipio de Vaqueros. Era
      gobernador interino el Coronel Hernán J.M. Risso Patrón y ministro de
      gobierno el Teniente Coronel Rodolfo Whener. El primer intendente fue
      Julio Catalán Arellano desde el 15 de octubre de 1970 hasta el 25 de mayo
      de 1973, siendo su secretaria tesorera, la señora Olimpia Flores de Tapia.
      El Municipio funciona en el terreno donado por la sucesión del doctor
      Carlos Serrey, donde se levantó el edifició bajo la dirección de Humberto
      Bini.
    </p>
    <p>
      6 de agosto de 1816: Desde su cuartel general en Jujuy, Güemes expidió una
      proclama a sus compañeros de armas comunicándoles la Declaración de la
      Independencia por parte del Congreso de Tucumán y exhortándolos a
      continuar combatiendo en amplia confianza en el pueblo de la Intendencia
      de Salta.
    </p>
    <p>
      6 de agosto de 1816:&nbsp;El gobernador de la Intendencia de Salta, don
      Martín Miguel de Güemes, juró la Independencia de las Provincias Unidas de
      Sud América junto con las autoridades y principales vecinos de la ciudad
      de Jujuy, reunidos en Cabildo Abierto.
    </p>
    <p>
      6 de agosto de 1932: Falleció en Villa Allende (Córdoba), Francisco Javier
      Ortiz. Periodista de: “La Actualidad”, “El Sereno”, y otros periódicos.
      Ministro de gobierno. Compuso la zamba “La Varelita” con don Isidoro
      López. Redactó el “Código Rural de la provincia de Salta”.
    </p>
    <p>
      6 de agosto de 1938: Se fundó en la ciudad de Salta, el Centro Boliviano
      de Socorros Mutos.
    </p>
    <p>
      7 de agosto de 1786: Nació en Salta, Cayetano González. Se doctoró en
      Derecho Canónico en la Universidad de Chuquisaca. De regreso en Salta,
      recibió la ordenación sacerdotal de manos del primer obispo de Salta,
      monseñor Videla del Pino. Colaboró con Güemes. En 1821 fue elegido
      diputado a la sala de Representantes (ex Cabildo). Fue párroco en Perico
      del Carmen y La Caldera, donde dejó en sus libros parroquiales vivas
      protestas contra el gobernador Manuel Antonio Saravia. En octubre de 1844,
      durante los terremotos que sacudieron a Salta, improvisó una tribuna en la
      calle, frente a la Catedral, arengó al pueblo ante las tradicionale
      simágenes del Señor y la Virgen del Milagro. Tuvo que emigrar a Bolivia
      por problemas con el gobernador Saravia.
    </p>
    <p>
      7 de agosto de 1916: Nació en Tucumán Eugenio Apolinar Romero, contador
      público y primer presidente del Instituto provincial de Seguros de Salta.
    </p>
    <p>
      8 de agosto de 1776: Por Cédula Real España creó el Virreinato del Río de
      La Plata, tomando en cuenta la conveniencia de que esta nueva división
      territorial accediera tanto al Oceáno Pacífico como al Atlántico. La
      intendencia de Salta del Tucumán, era el centro de tan vasto y variado
      territorio. Su ubicación geográfica la convirtió en la conjunción de las
      corrientes comerciales entre Buenos Aires y Lima, alcanzando por ello un
      alto nivel de riqueza y cultura.
    </p>
    <p>
      8 de agosto de 1800: Nació en Salta, Aarón Castellanos. Ingresó al
      escuadrón “Los Infernales”. Junto con Victorino Solá y el doctor Pablo
      Soria, exploraron el Bermejo para comprobar y demostrar su navegabilidad,
      pero este intento tuvo un desenlace inesperado, pues al desembocar en la
      costa paraguaya por el impulso del acaudalado río, fue apresado por las
      fuerzas policiales del presidente Francia, que gobernaba el Paraguay,
      siendo detenidos por espacio de 6 años. Recuperaron su libertad en 1830.
      Propuso organizar colonizaciones en el interior del país, particularmente
      en Chubut y Río Negro, pero su propuesta no tuvo acogida. Ofreció idéntica
      proposición al gobernador de Santa Fe, don Domingo Crespo, quien estudió y
      aprobó el plan presentado por Castellanos. Firmaron un contrato oficial el
      15 de junio de 1853, donde se autorizaba el ingreso de un millar de
      familias de agricultores europeos para ser radicados en diversos lugares
      de la campaña santafesina. Castellanos viajó a Europa y contrató colonos y
      logró hacer surgir la primera colonia de inmigrantes en la Argentina que
      denominó “Esperanza” cuya fundación se concretó el 8 de setiembre de 1853.
      Hoy Esperanza, es una pujante y floreciente ciudad. Por esta empresa,
      Aarón Castellanos se ubica en el consenso nacional como el primer
      colonizador de la Argentina. Murió en Rosario el 1º de abril de 1880. La
      ciudad de Esperanza venera y recuerda siempre a su fundador. Ha erigido un
      monumento a la memoria de este ilustre salteño.
    </p>
    <p>
      8 de agosto de 1850: Murió en Campo Santo, don José Antonino Fernández
      Cornejo. Nació en Salta en 1765. Su padre era oriundo de Locumba, Tacna,
      hoy territorio chileno. Llegó a Salta del Tucumán con la misión de
      expulsar a los jesuitas. Afincóse en la Hacienda de Campo Santo, que le
      fuera asignada en merced. Siguiendo la tradición familiar José Antonino
      ingresó a la carrera de las armas a temprana edad. Alistóse en los
      ejércitos del Rey con su hermano Juan José. Entre 1780 y 1790 realizó
      consu padre y su hermano exploraciones a lo largo del Bermejo. Luego
      comandó una expedición al Chaco Gualamba, para someter a los indios Tobas,
      en 1801 una segunda expedición. En 1803 fue designado comandante del
      fuerte del Río del Valle y un año después era nombrado comandante de
      frontera de la intendencia de Salta. Fue alcalde de 2º voto del cabildo de
      Salta. Em 1810 adhirió a la causa patriótica. Prestó servicio en el
      ejército auxiliar del norte, con el grado de teniente coronel. Participó
      en Huaqui, batalla de Tucumán y Salta, luego de la cual fue ascendido a
      coronel. Belgrano lo designó comandante general de frontera. En 1814
      asumió por cinco meses el mando gubernativo de la provincia. Tuvo
      desinteligencias con Martín Miguel de Güemes. Producida la muerte de
      Güemes firmó un armisticio con el general Olañeta, el 20 de agosto de
      1821, resultó electo gobernador. Fue derrocado por los Güemistas,
      asumiendo el coronel Pablo de Latorre. Un año después era nombrado
      teniente gobernador de Jujuy hasta 1825. Luego se lo designó comandante
      general de fronteras y recibía del gobernador de Salta, el general Antonio
      Alvarez de Arenales, el ascenso al grado de general. En 1834 asumió por
      tercer vez el gobierno de la provincia, depuesto por las fuerzas federales
      que comandaba el general Felipe Heredia. Se expatrió en Bolivia. Regresó
      en 1840 para colaborar con la Liga del Norte y Lavalle, al ser derrotado
      huyó de nuevo a Bolivia. Después de Caseros regresó alejado de toda
      militancia política. Retornó a su Hacienda de Campo Santo, donde murió.
      Desde 1950, un siglo después de su muerte, sus restos descansan en el
      panteón de las Glorias del Norte, en la Catedral de Salta.
    </p>
    <p>
      8 de agosto de 1925: Se graduó como médico el Dr. Andrés Cronejo. Fue
      Director del Hospital del Milagro. Fue Consultor honorario de la
      especialidad de Dermatología y enfermedades regionales del Instituto del
      Bocio. Fue representante del Círculo Médico de Salta, en el sepelio del
      profesor Dr. Salvador Mazza, en el año 1946. El 9 de agosto de 1896, nació
      en Salta. Murió en Salta el 7 de marzo de 1965.
    </p>
    <p>
      8 de agosto de 1989: El gobernador de la provincia de Salta, el contador
      público nacional Hernán Hipólito Cornejo, declaró ciudad a Rosario de
      Lerma. Era intendente de esa ciduad, Lucio Andrés Domínguez.
    </p>
    <p>
      9 de agosto de 1821: Se sancionó la Primera Constitución Salteña y a
      continuación fue elegido gobernador el coronel José Antonino Fernández
      Cornejo. Días más tardes, el 20 de agosto, firmó un tratado con Olañeta, a
      través del cual a los pueblos de Humahuaca y La Quiaca, se los declaraba
      neutral.
    </p>
    <p>
      9 de agosto de 1870: Murió en Jujuy, Pedro de la Corte y Rosas. Nació en
      Salta en 1779. Sus primeros pasos en la milicia fue bajo las órdenes del
      general Gorriti. participó en las batallas de Tucumán y de Salta. Luego
      pasó a integrar el flamante regimiento: Los Decididos de la Patria creado
      por el general Belgrano. Ya era capitán. En 1815, siempre en el mismo
      regimiento, pasó a las órdenes de Güemes. En 1868 el presidente Sarmiento
      ordenó rendirle honores militarescorrespondiente a su condición de
      guerrero de la independencia, además de acordarle una pensión vitalicia.
    </p>
    <p>
      9 de agosto de 1896: Nació en Salta, Andrés Cornejo. Se graduó de médico
      el 8 de agosto de 1925. Director del «Hospital del Milagro». Murió en
      Salta el 7 de marzo de 1965. Fue consultor honorario de la especialidad de
      Dermatólogo y efnfermedades regional del Instituto del Bocio. Fue
      representate del Círculo Médico de Salta, en el sepelio del profesor
      doctor Salvador Mazza en el año 1946.
    </p>
    <p>
      9 de agosto de 1914: Asumió la presidencia de la Nación, el salteño
      Victorino de la Plaza.
    </p>
    <p>
      9 de agosto de 1918: Asumió como interventor de la provincia de Salta, el
      doctor Manuel Carlés. Fundó la Liga Patriótica Argentina. Hizo construir
      el panteón de las Glorias del Norte.
    </p>
    <p>
      10 de agosto de 1845: Murió en Salta, Pedro Lucas Díaz de Zambrano. Se
      dedicó al comercio de mulas con el Alto y Bajo Perú. Asistió como testigo
      a los levantamientos de Cochabamba y La Paz en 1809. Colaboró con dinero y
      ganado en pie para la prosecución de la campaña militar que emprendía
      Belgrano. Si bien se opuso a la política del Héroe Gaucho, después de su
      muerte fue su incansable defensor. Contribuyó con su esposa a la refacción
      de la iglesia de Chicoana y la del templo de Sumalao.
    </p>
    <p>
      10 de agosto de 1891: El municipio de Metán recibió un ofrecimiento de los
      señores Enrique Lona, Osvaldo y Corina Sierra, por el que donan una lonja
      del terreno para una calle que una a San José con el Pueblo Nuevo de la
      Estación, lo que es aceptado.
    </p>
    <p>
      10 de agosto de 1923: Nació en Salta, Julio Lardies González. Estudió en
      España la primaria y la secundaria y se graduó en Madrid de médico, en
      1947. En el año 1949 revalidó su título en Buenos Aires. Médico psiquiatra
      y legista. En 1953 se doctoró en Medicina de Buenos Aires y Presidente de
      la Sociedad Argentina de Hisotir de la medicina de 1990 a 1992. Publicó 88
      trabajos científicos y la Historia de la Psiquiatria Universal y
      Argentina. Logró importantes premios y reconocimientos de la Academia
      Nacional de Medicina. Este médico salteño reside en Capital Federal.
    </p>
    <p>
      10 de agosto de 1924: Murió en Salta, Miguel S. Ortiz. Abogado, ministro
      de gobierno. Gobernador de Salta desde el 9 de julio de 1881 hasta el 9 de
      julio de 1883. Durante su gobierno se construyó el matadero municipal.
      Fundador y presidente de “La Sociedad Rural Salteña” (1900). Ministro del
      interior.
    </p>
    <p>
      10 de agosto de 1954: Nació en Salta, Guillermo Pucci. Artista Plástico
      que realiza muestras desde 1973. Obtuvo importantes premios, entre los que
      se destacan el Primer Premio de Pintura de la Provincia de Salta. Ilustró
      libros de numerosos poetas salteños.
    </p>
    <p>
      11 de agosto de 1864: Nació en La Poma, provincia de Salta, Nicolás
      Lozano. En Salta estudió las primeras letras y latín. Estudió los estudios
      secundarios en Córdoba. En 1883 se inscribió en la Facultad de Medicina de
      Buenos Aires. Por su actuación contra el cólera el gobierno de Salta le
      dió una medalla de plata. Se graduó en 1890. Fue médico de los hospitales
      Alvarez y Muñíz. Trabajó en Salta contra el paludismo. Ocupó la
      presidencia de la Sociedad Científica Argentina. Consejero de la Cruz Roja
      Argentina. La Academia Nacional de Medicina lo incorporó el 10 de
      noviembre de 1927. Falleció súbitamente el 3 de marzo de 1938.
    </p>
    <p>
      11 de agosto de 1870: Nació Washington Alvarez. Realizó la primaria y
      secundaria en Salta. Luego pasó a la facultad de Medicina en Buenos Aires.
      Una vez recibido fue médico de ferrocarriles del Estado. Director del
      Hospital Argerich. Director de la Asistenai Pública. En 1925 dejó fundada
      la Filial de la Cruz Roja Argentina en Salta. En 1955 fue declarado decano
      de los médicos argentinos. Murió el 27 de junio de 1961 a los 91 años,
      cuando era considerado el patriarca de la medicina salteña.
    </p>
    <p>
      11 de agosto de 1937: Nació en Salta, Ariel Petrocelli. Poeta reconocido
      en todo el país. Publicó en versos “Canciones”, “Las Rondas”, “Ceremonial
      de la Copla”. Tiene obras terminadas que esperan editor. Autor de
      laureadas canciones, entre las que recordamos: “Cuando tenga la tierra”,
      “El antigal”, “El seclanteño”, “Sembrador Argentino”. Su obra fue
      interpretada por la gran mayoría de artistas argentinos.
    </p>
    <p>
      12 de agosto de 1806: Güemes penetró a caballo en el Río de la Plata a
      donde había marchado su compañía con motivo de la invasión de los ingleses
      comandando un pelotón de husares y obligó a rendirse al navio británico
      “Justina” que había encallado frente al puerto de Buenos Aires. Las tropas
      patriotas libraron una batalla gloriosa ante los ingleses en Buenos Aires.
      El general inglés izó bandera de parlamento y al fin se rindió, entregando
      1.200 prisioneros, cañones, fusiles comoasí también sus banderas y
      estandartes. Es una jornada de gloria que se recuerda como el “Día de la
      Reconquista”.
    </p>
    <p>
      12 de agosto de 1866: Nació en Salta, Bernardo Frías. Fueron sus padres
      don Benigno Frías y doña Juana Mollinedo de Frías, siendo el cuarto hijo
      varón de este matrimonio, heredero de ricas tradiciones sociales en la
      provincia. Publicó: Tradiciones Históricas. En verso: “Mis versos”, una
      novela llamada “La Colegiala”. Abogado. Falleció en el Valle de Lerma,
      provincia de Salta, el 17 de diciembre de 1930. Su obra más destacada es
      la “Historia del General Güemes” en 6 tomos.
    </p>
    <p>
      12 de agosto de 1944: Fueron declaradas Monumento Histórico por decreto Nº
      21.483, las Ruinas de Tolombón. Los restos de esta ciudad prehispánica,
      considerada como el centro de la población diaguita, por haber sido la
      capital de los diaguitas, baluarte del héroe indígena Juan de Calchaquí.
    </p>
    <p>
      12 de agosto de 1957: Nació en Orán Marcelo Lara Gros. Escribe poemas.
      Figura en una antología de Orán, Salta.
    </p>
    <p>
      12 de agosto de 1967: Falleció Ricardo Fernández Dore, quien fue un
      destacado directivo de Horizontes S.A. y de Diario El Tribuno.
    </p>
    <p>
      12 de agosto de 1982:&nbsp;Falleció el talentoso periodista Luis Mario
      Gbhara, quien firmaba sus escritos como Mario Rios. De gran formación
      humanística, su labor fue reconocida por los salteños a través de sus
      aportes en diario El Tribuno y canal 11.
    </p>
    <p>
      13 de agosto de 1870: Falleció en Chile, María Josefa Cabezón de Vilariño,
      nacida en Salta en 1807. Hizóse cargo de la Dirección del Colegio fundado
      por sus hermanas en Santiago (Chile) a partir de 1850 y durante 20 años,
      demostrando su vocación docente al introducir métodos didácticos que
      fueron considerados lo más modernos de su tiempo. Además de su tarea
      docente, dedicó gran parte de su tiempo a la beneficencia (donaba sus
      sueldos para esas obras de caridad). Sus hijas tomaron sobre sí la honrasa
      tradición familiar, prosiguiendo a obra educativa. Fundaron un colegio que
      lleva su nombre.
    </p>
    <p>
      13 de agosto de 1925: Nació en Monte Carmelo, Orán, provincia de Salta,
      Antonio Osvaldo Pintado. Maestro Normal Nacional. En 1943 ganó el primer
      premio de un concurso literario para estudiantes secundarios de Salta.
      Continua escribiendo y dedicándose a la docencia en establecimientos
      secundarios de Orán.
    </p>
    <p>
      13 de agosto de 1931: Nació en Salta, Eduardo Madeo, quien fuera
      integrante del famoso conjunto folklórico Los Fronterios. Viajó por el
      mundo llevando el mensaje de poesias y de la música de Salta. Se desempeñó
      como Director de Acción Cultural de la Provincia.
    </p>
    <p>
      13 de agosto de 1979: Se inauguró la sucursal de la ciudad de General
      Güemes, del Banco de Préstamos y Asistencia Social, de la provincia de
      Salta. Su primer erente fue el señor Eduardo Ayón.
    </p>
    <p>
      14 de agosto de 1821: Asumió la primera magistratura provincial de Salta,
      el coronel mayor José Antonino Fernández Cornejo, quien pocos días
      después, nombró teniente de gobernador de dicha jurisprudencia al coronel
      mayor Agustín Dávila.
    </p>
    <p>
      14 de agosto de 1836: El canónigo don José Agustín Molina, fue
      solemnemente reconocido por las autoridades civiles y eclesiásticas de
      Salta. El Sumo Pontífice, atento a los informes recibidos, decidió
      designar a Molina oriundo de Tucumán. Los gobienos de Tucumán, Santiago
      del Estero y Catamarca aceptaron de inmediato al canónigo Molina y el de
      Jujuy después. El gobierno de Salta deseaba en principio la desginación de
      José Gabriel de Figueroa. Hasta que el día mencionado (14 de agosto de
      1836), siendo ya gobernador de Salta don Felipe Heredia, se produjo el
      reconocimiento.
    </p>
    <p>
      14 de agosto de 1918. Nació en Cerrillos, en la provincia de Salta, Manuel
      José Castilla, quien se consagraría con el tiempo en una de las voces
      poéticas más importantes del país. Obtuvo el Primer Premio Nacional de
      Literatura y la Faja de Honor de la SADE, entre otros premios. Conocido en
      su aporte a la canción argentina.
    </p>
    <p>
      15 de agosto de 1762: Nació en Salta el canónigo y doctor José Ignacio
      Thames. Fue párroco de la Iglesia Catedral de Salta. En 1816 Tucumán lo
      designó representante ante el Congreso que se reunió. Murió en Salta, el 9
      de febrero de 1832.
    </p>
    <p>
      15 de agosto de 1809: Llegó a Salta Monseñor Nicolás Videla del Pino,
      quien salió de Asunción por vía fluvial en abril de 1808. Llegó a Santa
      Fe. Siguió por tierra al Chaco, llegando a la reducción de Abispones,
      donde tomó posesión del obsipado. En Abispones expedió varios documentos
      relacionados con la organización de la diócesis de Salta e inició una
      visita pastoral. Visitó Santiago del Estero, y luego San Miguel de
      Tucumán, antes de llegar a Salta.
    </p>
    <p>
      15 de agosto de 1873: Nació en Salta Luis Patrón Costas. Gobernador de
      Salta desde el 1º de mayo de 1936 hasta el 1º de mayo de 1940. Murió en
      Salta el 7 de agosto de 1952.
    </p>
    <p>
      15 de agosto de 1893: Tomó posesión del obispado de Salta, monseñor Pablo
      Padilla y Bárcena. El presidente Pellegrini lo propuso para obispo de
      Salta y el Papa León XIII lo designó el 19 de enero de 1893, cuando
      ejercía el poder ejecutivo nacional el doctor Luis Sáenz Peña. 8 años fue
      vicario capitular y sólo 5 años obispo diocesano de Salta. Monseñor
      Padilla nació en Jujuy, en 1848 y cuando tenía 28 años fue designado
      rector del seminario diocesano de Salta.
    </p>
    <p>
      15 de agosto de 1905: Nació el doctor Nolasco Cornejo Costas. Fue
      presidente en dos oportunidades del Circulo Médico de Salta. En los
      períodos: 1945-1946 y en 1957-1958.
    </p>
    <p>15 de agosto de 1929: Se fundó el Club San José de Metán.</p>
    <p>
      15 de agosto de 1944: Se hizo cargo del gobierno de la provincia de Salta,
      el Interventor Federal, doctor Arturo S. Fassio.
    </p>
    <p>
      15 de agosto de 1978: Se fundó en la ciudad de Salta, la Clínica Güemes.
    </p>
    <p>
      16 de agosto de 1857: Murió en Salta, José Manuel Chávez. Participó en las
      batallas de “Tucumán y Salta” (1812 y 1813). Peleó a las órdenes del
      general Gorriti. Antes de retirarse a la vida privada fue ascendido a
      “Coronel Graduado”.
    </p>
    <p>
      16 de agosto de 1907: Nació en Salta, Julio Díaz Villalba. Abogado. Ocupó
      diversos cargos políticos, llegando a ser gobernador interino de Salta.
      Como hombre de letras fue fecundo. Algunas de las obras publicadas:
      Antología Vana (1958); Casos del Coya Martín Bustamante (1963 – reeditado
      en 1964, 1966 y 1970); Tierra Mansa (1966); Bandera sin asta (1976); El
      reptil verde (1980-novela).
    </p>
    <p>
      16 de agosto de 1911: Por ordenanza Nº 12/97, el Concejo Deliberante de
      Aguaray, ordena Fijase como fecha de Fundación de la localidad de Aguaray
      el día 16 de agosto de 1911, establecida a través de un trabajo de
      investigación realizada por la Comisión Aguada del Zorro.
    </p>
    <p>
      16 de agosto de 1936: Nació en El Chaco, Héctor Manuel Canto. Profesor en
      Ciencias Exactas que dedicó su vida a la docencia. Fue Director de Cultura
      de Salta. Como dirigente político fue Diputado y Senador Provincial.
    </p>
    <p>
      16 de agosto de 1993: Se instaló en su nueva sede, la Asociación de
      Clínicas y Sanatorios privados de la provincia de Salta, en la calle
      Córdoba 295 de la ciudad de Salta. Se habilitó el primer piso, ya que
      sigue trabajando en la construcción de la sede definitiva.
    </p>
    <p>
      17 de agosto de 1917: En la Presidencia de Hipólito Irigoyen se creó en la
      ciudad de Salta la Escuela de Artes y Oficios, que estaba ubicada en la
      calle Buenos Aires Nº 269 de la ciudad de Salta. Era gobernador de la
      provincia don Abraham Cornejo. Su primer director fue el Ingeniero Jorge
      Alderete. Comenzó a funcionar el 17 de marzo de 1919 de 1949 a 1951 se
      llamó Escuela Industrial de la Nación. En 1964 se le impone el nombre
      Escuela Técnica Nº 42 “Alberto Einstein”. En la actualidad funciona en un
      moderno edificio ubicado en calle Tucumán N º 505, cuya construcción se
      debe a la tesonera y brillante gestión de su director, ya fallecido, don
      Virginio Canova. Actualmente la dirige el Prof. Carlos Tomás Seco como
      director y Eduardo Oscar Castillo como vicedirector. Allí se forman 1.300
      alumnos que egresan con los títulos de técnicos mécanicos, electricistas o
      técnicos mecánicos, electricista o técnicos en electrónica, luego de seis
      años de estudio.
    </p>
    <p>
      17 de junio de 1920: Falleció en Buenos Aires, el doctor en
      jurisprudencia, Indalecio Gómez. Nació en Molinos, provincia de Salta. La
      primaria la realizó en Molinos, y en la ciudad de Salta. El secundario, en
      Sucre, Bolivia. En Buenos Aires, en 1876 se doctoró en Jurisprudencia. Fue
      profesor en el colegio Nacional de Salta. Cónsul argentino en Iquique,
      Chile. En 1910 fue designado ministro del interior.
    </p>
    <p>
      17 de agosto de 1965:&nbsp;Nació en Tartagal, Marcelo Daniel Sagárnaga.
      Periodista que trabaja para distintos medios de Salta, entre los que se
      destacan Propuesta, FM Aries, Canal 2, Canal 11, Diario Eco del Norte,
      Diario El Tribuno y el diario nacional Página 12. Se especializa en temas
      culturales.
    </p>
    <p>
      17 de agosto de 1984: Culminó el ciclo de conferencias sobre Güemes,
      organizado por la Universidad Católica de Salta y el Instituto Güemesiano,
      con los auspicios de la Dirección de Cultura de la Provincia. Entre los
      catedráticos asistentes se destacan los siguientes: Luis Oscar Colmenares,
      Luisa Miller Astrada, María Teresa Cadena de Hessling, Esther María Torino
      y María Inés Garrido de Solá entre otros.
    </p>
    <p>
      18 de agosto de 1932: Nació en Concepción del Uruguay, provincia de Entre
      Ríos, Mario Saúl Banchik. Empresario que se desempeña como tesorero de la
      Cámara Argentina de la Construcción y es el Vicepresidente de la Cámara de
      Comercio Exterior de Salta, entidad organizadora de FERINOA.
    </p>
    <p>
      18 de agosto de 1950: Nació Eduardo Antonelli. Licenciado en Economia,
      Docente Universitario, investigador y político. Fue Concejal de la ciudad
      de Salta.
    </p>
    <p>
      18 de agosto de 1959: Nació en Rosario de la Frontera, Luis Alberto
      Torres. Profesor de danza y música en los talleres artísticos Jaime
      Dávalos. Es vicedirector del Ballet Norte y Sur. Realizó gira por el país
      y por países latinoamericanos.
    </p>
    <p>
      19 de agosto de 1944: Nació en Salta, Antonio Lovaglio Saravia. En 1968,
      se graduó de médico. Fue director médico de YPF (Salta) en 1975;
      Subgerente médico del Instituto Provincial de Seguros en 1979; Director
      del Hospital San Bernardo desde 1985 a 1989 hasta 1993. En la actualidad
      es el Ministro de Educación y Cultura de la Provincia de Salta.
    </p>
    <p>
      19 de agosto de 1946: Nació en Salta, Carlos Federico Pastrana Padilla.
      Destacado periodista que se desempeña como corresponsal del diario La
      Nación de Buenos Aires, entre otras actividades. Escribió con otros
      colegas un libro de periodismo escolar.
    </p>
    <p>
      19 de agosto de 1956: Culminó en Salta, el Primer Congreso del Apostolado
      Laico. SE realizó del 15 al 19, con la participación de delegaciones
      parroquiales de toda la arquidiócesis y una representación de la Acción
      Católica Nacional.
    </p>
    <p>
      20 de agosto de 1805: Abrió sus puertas el Hospital San Andrés, bajo la
      atención de los padres betlemitas. Funcionó en el edificio del convento de
      San Bernardo en la ciudad de Salta.
    </p>
    <p>
      20 de agosto de 1836: Nació en Salta, Bernardo Fábregas Mollinedo. Hizo la
      escuela primaria en Salta. Recomendado por el general Rudencio Alvarado se
      trasladó a Córdoba para adquirir mayores conocimientos. Ingresó al colegio
      Monserrat de Córdoba. Era un excelente alumno, pero descubrió su vocación
      clerical. La Diócesis de Salta le concedió una beca para estudiar en el
      recién fundado colegio Pío Latinoamericano de Roma, Italia. Se embarcó en
      1859. Fue ordenado sacerdote el 8 de diciembre de 1861. Poco después
      obtuvo el doctorado en Teología. A los cuatro meses de ordenado enfermó
      gravemente, muriendo en Roma, en 1862. En el mismo colegio le fue dedicado
      un busto de mármol a su memoria.
    </p>
    <p>
      20 de agosto de 1941: A los 94 años en Buenos Aires, murió Francisco
      Castellanos Figueroa. Nació en Salta en 1847. Se graduó de médico en 1875.
      Rector del Colegio Nacional de Catamarca. Diputado Nacional por Catamarca
      en 1886. Miembro del Consejo Nacional de Higiene (Buenos Aires).
    </p>
    <p>
      20 de agosto de 1942: Murió en Salta, Avelino Figueroa. Nació en Salta en
      1857. Se dedicó a la política. Fue concejal (1893-1896), diputado
      provincial, jefe de policía, ministro de gobierno, director del Banco
      provincial de Salta. Fue gobernador electo de la provincia desde el 20 de
      febrero de 1910 hasta el 20 de febrero de 1913. En Buenos Aires fue
      director del Banco de la Nación Argentina y a su regreso en Salta fue
      varias veces presidente del Consejo General de Educación.
    </p>
    <p>
      20 de agosto de 1950: Murió en Salta, Artidorio Cresceri. Fue inspector de
      música, autor del “Himno Salteño”, “La López Pereyra”, zamba salteña
      popular que es interpretada por la mayoría de los artistas argentinos y
      por todos los salteños.
    </p>
    <p>
      20 de agosto de 1953: Nació en Colonia Santa Rosa, Emilio Haro Galli.
      Artista radicado en Cafayate que se dedica a la pintura, a la cerámica y
      se inicia en las artes.
    </p>
    <p>
      20 de agosto de 1956:&nbsp;Nació en Salta, Arturo Botelli. Músico docente
      que está a cargo de la vice dirección de la Escuela Provincial de música
      de la Provincia. Investigador de la música de Salta. Docente en la
      Universidad Católica de Salta.
    </p>
    <p>
      21 de agosto de 1949: Se fundó en Salta, diario El Tribuno. Es la
      publicación más importante de la provincia. Es permanente gestor de
      importantes hechos culturales. Actualmente, su director es el señor
      Roberto Eduardo Romero.
    </p>
    <p>
      21 de agosto de 1958: Murió en Bernal, Buenos Aires, Daniel E. Olmos.
      Ingeniero Civil, docente. Director General de Geodesia y Catastro en la
      provincia de Buenos Aires, director de catastro en la provincia de Salta.
    </p>
    <p>
      21 de agosto de 1969: El Ministro de Gobierno de la provincia de Salta,
      doctor Julio Díaz Villalba,se hizo cargo del gobierno de la provincia,
      hasta la asunción del Interventor Federal, doctor Carlos Felipe Ponce
      Martínez.
    </p>
    <p>
      22 de agosto de 1832: Falleció en Buenos Aires, Manuel Antonio de Castro.
      Nació en Salta en 1772. Doctorado en jurisprudencia en Chuquisaca. Se
      encaminó a Buenos Aires para adherirse a la causa patriota. Al haberse
      desempeñado como ayudante del Virrey Cisneros al llegar a Buenos Aires, le
      atrajo la desconfianza de algunos miembros de la Junto de Gobierno, a
      cuyas instancias le fue decretada prisión el 24 de junio y su
      confinamiento en Córdoba; fue absuelto al poder demostrar su adhesión a la
      causa patriota. En reivincación fue designado vocal de la Cámara de
      Apelaciones de Buenos Aires. En 1815 fue Director de al Academia de
      Jurisprudencia. En 1816 fue director de “El Observador Americano”
      (periódico). En 1817 gobernador de la provincia de Córdoba. En 1824
      presidente del Congreso Constituyente.
    </p>
    <p>
      22 de agosto de 1952: Se fundó en la ciudad de Salta, el Hogar Escuela Nº
      914 Carmen Puch de Güemes, para proporcionar una educación básica común a
      niños de edad escolar, con situación socio-económica, afectiva especial.
      La actual directora es la señora Emma Rosa Macchía de Carreras.
    </p>
    <p>
      23 de agosto de 1810: El gobierno patrio designó al coronel Feliciano
      Chiclana, primer gobernador de Salta.
    </p>
    <p>
      23 de agosto de 1817: El coronel Manuel Eduardo Arias, perteneciente al
      ejército de Güemes, derrotó a los realistas, que mandaba el brigadier
      Pedro Antonio de Olañeta, en Los Toldos, provincia de Salta.
    </p>
    <p>
      23 de agosto de 1921: Nació en Vicuña Mackenna, Río Cuarto, Córdoba,
      Francisco de la Cruz Muguerza. Ingresó a la orden franciscana en 1934. Se
      ordenó sacerdote en La Plata el 21 de diciembre de 1946. En 1947 viajó a
      Roma donde se doctoró en Filosofía. En 1951 retornó al país, siendo
      designado profesor de la casa de estudios de la provincia franciscana del
      Río de la Plata. En 1952 fue nombrado primer rector del postulantado de
      adultos, hasta que se lo designó primer obispo de la diócesis de la Nueva
      Orán, el 14 de julio de 1961. La consagración episcopal se llevó a cabo el
      24 de setiembre de 1961 en San Antonio de Padua, Buenos Aires, siendo
      consagrado por el arzobispo de Salta, monseñor Roberto José Tavella. Tomó
      posesión de sus funciones apostólicas en Orán, el 21 de octubre de 1961.
      Por su obra en el obispado quedó en la historia de Orán. Murió en la
      capital federal el 30 de abril de 1969. Sus restos descansan en la
      catedral de Oran.
    </p>
    <p>
      24 de agosto de 1843:&nbsp;Nació en Salta, Dois Fábregas Mollinado. Se
      dedicó a la carrera de militar. Actuó en la guerra del Paraguay. Integró
      las fuerzas del General Roca en la campaña del Desierto. Ostentando el
      grado de Coronel, falleció en Buenos Aires, el 12 de setiembre de 1896.
    </p>
    <p>
      24 de agosto de 1944: Murió a los 82 años, Francisco Centeno (hijo). Nació
      en Salta en 1862. Director de la Biblioteca y Archivo de la Cancillería.
      Recopiló documentos que publicó en la “Revista de Derecho, Historia y
      Letras”. Títulos como: “Documentación Histórica de la Sociedad de
      Beneficencia” (1909), “Catálogo de documentos del Archivo de Indias de
      Sevilla referentes a la República Argentina” (1911), “República Argentina:
      Tratados, Convenciones, Protocolos, Actas y Acuerdo Internacionales” (9
      tomos publicados en 1911), “Virutas Históricas” (3 tomos 1919-1935),
      “Sumario Histórico”, “Huellas del Pasado” entre otras obras.
    </p>
    <p>
      24 de agosto de 1995: Se inauguró en La Caldera, una central digital
      electrónica con una capacidad para 256 líneas telefónicas.
    </p>
    <p>
      25 de agosto de 1809: Tomó posesión el primer obispo de Salta, monseñor
      Nicolás Videla del Pino.
    </p>
    <p>
      25 de agosto de 1843: Nació en Salta, Luis Fábregas Mollinedo. Se dedicó a
      la carrera de las armas. Actuó en la guerra del Paraguay. Integró las
      fuerzas del general Roca en la campañ del Desierto. Ostentando el grado de
      coronel, falleció en Buenos Aires el 12 de septiembre de 1896.
    </p>
    <p>
      25 de agosto de 1882: Nació en Salta, Luis Colmenares. Senador por La Poma
      por la U.C.R. 1934-1938. De regreso a Salta relató las vicisitudes de su
      paso por la Marina de Guerra, las que publicó con el título “Impresiones
      de Viaje” (Salta 1903).
    </p>
    <p>
      25 de agosto de 1926:&nbsp;El padre Gabriel Tomasini celebró su primera
      misa en Tartagal. A este sacerdote se debía la primera Iglesia en
      Tartagal, que era un galpón de madera.
    </p>
    <p>
      25 de agosto de 1929: Murió en Formosa, Carlos López Pereyra. Abogado,
      Doctor en Jurisprudencia, Periodista, Director de “El Círculo”. Siendo
      Juez de instrucción sentó jurisprudencia acerca del tema: emoción violenta
      como factor en favor del acusado y en este caso siendo Artidorio Cresceri
      el beneficiario de tan notable dictamen que lo absolvía de un homicidio
      involuntario, en prueba de gratitud le dedicó una zamba que compuso en su
      honor y que titulara: “La López Pereyra”.
    </p>
    <p>
      25 de agosto de 1948: Violento terremoto en Metán, entre las 03:10 y 03:12
      de la madrugada. El epicentro fue ubicado en Finca el Rey. Dio origen a
      los solemnes cultos en honor del Señor y de la Virgen del Milatro en
      Metán.
    </p>
    <p>
      25 de agosto de 1966: Monseñor Carlos Mariano Pérez, arzobispo de Salta,
      dispuso oficialmente la creación de la parroquia del Señor y de la Virgen
      del Milagro en Metán. Posesionó al párroco José Vicente Tejerina
      Monserrat.
    </p>
    <p>
      26 de agosto de 1812: El coronel Eustaquio Díaz Vélez, perteneciente al
      ejército mandado por el general doctor Manuel Belgrano, derrotó a la
      guardia del ejército realista mandado por el general Pío Tristán, en
      Cobos, provincia de satla.
    </p>
    <p>
      26 de agosto de 1878: Nació en Cafayate, Salta, Ceferino Velarde. Durante
      26 años ocupó una banca en la cámarad de diputados (1904-1930). Fue
      designado intendente municipal de Salta en 1939, por el gobernador Luis
      Patrón Costas y confirmado por el sucesor doctor Abraham Cornejo. Como
      intendente emplazó el Cristo Redentor en la cumbre del cerro San Bernardo
      (1937). Falleció en Salta el 18 de agosto de 1966.
    </p>
    <p>
      26 de agosto de 1956: Se inauguró la Capilla San Roque de Talapampa, en la
      provincia de Salta. La obra se inició enel año 1950.
    </p>
    <p>
      27 de agosto de 1852: El coronel Aniceto Latorre, al frente del ejército
      salteño, derrotó al general José María Saravia, en Palata o Río del
      Rosario, provincia de Salta.
    </p>
    <p>
      27 de agosto de 1933: Nació en Salta, Néstor Salvador Quintana. Periodista
      destacado con actuación en distintos medios de Salta. Docente en la
      Universidad Católica de Salta y en la de Santiago del Estero. Fue diputado
      provincial y también Intendente de Salta. Fue director de Radio Nacional
      Salta, director de prensa de la provincia en dos oportunidades, secretario
      del Consejo de Educación. Publicó dos libros en colaboración. Fue
      periodista de El Intransigente, Norte y El Tribuno; colabora en
      seminarios, en otras publicaciones, en radio y televisión.
    </p>
    <p>
      27 de agosto de 1983: Se inauguró el edificio del Círculo Médico de Salta,
      ubicado en la calle Urquiza 153. El terreno había sido adquirido en el año
      1980. El edificio es de líneas modernas y es el trabajo y la inspiración
      de tres arquitectas esposas de médicos.
    </p>
    <p>
      28 de agosto de 1910: El consejo de Metán abonó la suma de $ 1.549,80 m/n
      importe de la primera cuota “por la compra de 16 faroles sistema Falucho
      para el alumbramiento público, más $ 146,60 m/n, importe de veinte cajones
      de nafta y 112 mechas de repuesto”.
    </p>
    <p>
      28 de agosto de 1938: Murió en Buenos Aires, Juan B. Peñalba. Nació en
      1867 en Cafayate (Salta). Médico, profesor del Colegio Nacional, rector
      del Colegio Nacional, vocal del Consejo General de Educación, legislador
      provincial.
    </p>
    <p>
      28 de agosto de 1949: Nació en Salta, el poeta y escritor Juan Ahuerma
      Salazar. Logró importantes premios literarios. Publicó varios libros:
      “Territorio Libre” (poemas), “Alias cara de caballo” y algunos otros.
      Colabora con periódicos y revistas especializadas. Autor de la bella
      novela “La república cooperativa del Tucumán”. Dirigió la publicación “El
      Pájaro Cultural”. Es psicólogo.
    </p>
    <p>
      28 de agosto de 1956:&nbsp;La Asociación de Clínicas y Sanatorios privados
      de la provincia de Salta, se reunió para considerar la incorporación en
      esa entidad de tres nuevos establecimientos: Instituto Médico de Salta,
      Clínica Cruz Azul y Clínica Córdoba. Se resolvíó por unanimidad la
      incorporación. La comisión directiva quedó constituida por: los doctores
      Marcos Gutiérrez (presidente), Hugo Saravia Cánepa (vicepresidente),
      Gaspar J: Solá Torino (secretario), Aurelio Rodríguez Morales (tesorero) y
      (vocales) Raul A. Caro, Jorge Aguilar Benitez y Julio Abdo.
    </p>
    <p>
      28 de agosto de 1979: Se habilitó la Iglesia rural de San Agustín, Salta,
      en coincidencia con la solemne y tradicional celebración de San Agustín.
      Se levantó con el apoyo comunal.
    </p>
    <p>
      29 de agosto de 1563:&nbsp;Fue creada, por Cédula Real de Felipe II, la
      gobernación del Tucumán, que estaba integrada por las actuales provincias
      de Jujuy, Salta, Catamarca, Tucumán, La Rioja y Santiago del Estero, que
      era designada ciudad capitalde esta gobernación, dependiente del
      Virreinato del Perú. Antes Santiago del Estero dependía de la gobernación
      de Chile.
    </p>
    <p>
      29 de agosto de 1814: Desapruébanse las elecciones para diputados por
      Salta y Jujuy. Facúltase al Poder Ejecutivo para entrar en relaciones con
      la Corte de España.
    </p>
    <p>
      29 de agosto de 1882: Nació en Salta, Juan Bautista Molina. Egresó del
      Colegio Militar, de la Escuela de Tiro y la Ecuela Superior de Guerra,
      profesor en el Colegio Militar, agregado militar en la Legación Argentina
      ante Alemania, general de Brigada. Falleció en Buenos Aires en 1962.
    </p>
    <p>
      30 de agosto de 1778: Nació en Salta, Tomás de Allende. Fue abogado y
      doctor en leyes. Fue gobernador de Salta desde el 24 de diciembre de 1810
      hasta el 11 de junio de 1811. Falleció en Potosí el 9 de abril de 1815.
    </p>
    <p>
      30 de agosto de 1867: El coronel Pedro Frías del ejército nacional,
      derrotó al caudillo Felipe Varela, en Molinos provincia de Salta.
    </p>
    <p>
      30 de agosto de 1914: Nació en Salta, Carlos Luis Garcia Bes. Luego de los
      estudios primarios y secundarios, se trasladó a la capital federal donde
      estudió en la escuela nacional de artes. Obtuvo el título de profesor
      nacional de dibujo en 1938. Luego se especializó en la Escuela Superior de
      Bellas Artes de la Nación “Ernesto de la Cárcova”, donde se recibió de
      profesor superior de dibujo y pintura en 1943. Regresó a Salta siendo
      profesor en el colegio nacional. Luego fundó y fue su primer director de
      la escuela de Bellas Artes “Tomás Cabrera”. Como artista fue múltiple:
      plástico, dibujante, pintor, titiritero, ceramista, muralista. Realizó
      tapices con singular maestría. Expuso en Estados Unidos, Canadá, España,
      Francia, Alemania, Italia. Fue director de cultura de la provincia.
      Falleció en Salta el 15 de noviembre de 1978.
    </p>
    <p>
      30 de agosto de 1977: Se inauguró la moderna Iglesia-Catedral de San Ramón
      de la Nueva Orán. Está en la esquina de Carlos Pellegrini y 9 de julio. Se
      levantó por iniciativa y gestión de monseñor Manuel Guirao.
    </p>
    <p>
      31 de agosto de 1794: Ramón García de León y Pizarro, fundó San Ramón de
      la Nueva Orán. Fue la última fundación que realizaron los conquistadores
      españoles.
    </p>
    <p>
      31 de agosto de 1810: Son elegidos diputados por los Cabildos de Catamarca
      y de Salta, para integrar la Primera Junta, Francisco de Acuña y el doctor
      Francisco de Gurruchaga, respectivamente.
    </p>
    <p>
      31 de agosto de 1841: Nació en Salta, Matías Linares Zanzetenea. Se ordenó
      sacerdote en 1864. Fue consagrado obispo de Salta y Jujuy el 8 de febrero
      de 1898. Falleció el 2 de abril de 1914.
    </p>
    <p>
      31 de agosto de 1864: Nació en La Poma, Salta, Nicolás Lozano. Médico. Fue
      presidente del departamnto nacional de higiene. Concretó la creación de la
      Colonia Neutro – Siquiátrica del norte. Fue presidente de la Cruz Roja
      Argentina. Colaboró en diarios y revistas especializados. Murió en Buenos
      Aires el 3 de marzo de 1938.
    </p>
    <p>
      31 de agosto de 1887: Nació en Metán, provincia de Salta, Corina Lona. A
      los 17 años se recibió de maestra. Con Sara Rodríguez Munizaga alquilaron
      con sus sueldos, una casa de alto en la calle Alberdi para darle Hogar a
      los Ciegos. El gobernador Ricardo Durand, levantó el Hogar de Ciegos, que
      inauguró en 1955, lleva por resolución del gobierno el nombre de su
      fundadora y alma mater: Corina Lona. Falleció el 23 de enero de 1961.
    </p>
    <p>
      31 de agosto de 1897: Falleció en Salta, a los 85 años de edad, el doctor
      Celedonio de la Cuesta. Nació en Salta en 1812. Se graduó de abogado en
      1835, en Buenos Aires. En su tierra se dedicó a la profesión y a la
      docencia. participó en las fuerzas militares, actuando en acciones
      bélicas, hasta graduarse de comandante. Luchó en La Rioja, San Juan,
      Mendoza, San Luis. Fue vice rector del Colegio Nacional de Salta.
    </p>
    <p>
      31 de agosto de 1977: Fue consagrada la catedral de Orán, que había
      comenzado a construirse el 31 de enero de 1973. A la ceremonia asistieron
      el nuncio de su santidad monseñor Pío Laghi, el arzobispo Carlos Mariano
      Pérez, los obispos de Orán, Jujuy, Catamarca, Concepción de Tucumán, y el
      auxiliar deSalta y los prelados de Cafayate y Humahuaca. Era Obispo de
      Orán Manuel Guirao, luego obispo de Santiago del Estero.
    </p>
    <p>
      31 de agosto de 1994: Falleció la profesora de Historia, Maria Teresa
      Cadena de Hessling. Docente e historiadora. Fue miembro académico del
      Instituto Güemesiano de Salta y del Instituto San Felipe y Santiago de
      Estudios Históricos de Salta. Es autora entre otros trabajos de la
      Historia y Geografía de Salta. Colaboró en diaros, revistas y radios.
    </p>
    <hr />
    <CommentsFB></CommentsFB>
  </Layout>
)

export default EfeEnero
